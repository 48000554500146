<template>
  <form-field-text
    :hint="hint"
    :persistent-hint="!!hint"
    :value="value"
    input-type="number"
    label="amount"
    required
    single-line
    v-bind="$attrs"
    @blur="onBlur"
    @save="onApplyPrice"
    @input:debounce="onSetPrice"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { isNaN, isNil } from "lodash";

@Component
export default class SimpleUnitPriceField extends Vue {
  @Prop([String, Number]) readonly value!: number;
  @Prop(String) readonly hint!: string;
  @Prop(Boolean) readonly updateOnBlur!: boolean;

  onSetPrice(fValue: number) {
    if (isNil(fValue) || isNaN(fValue)) {
      fValue = 0;
    }

    if (!this.updateOnBlur) {
      this.$emit("price:update", fValue);
    }
  }

  onBlur(fValue: number) {
    if (isNil(fValue) || isNaN(fValue)) {
      fValue = 0;
    }

    if (this.updateOnBlur) {
      this.$emit("price:update", fValue);
    }
  }

  onApplyPrice() {
    this.$emit("price:apply", true);
  }
}
</script>
